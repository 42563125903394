import React from 'react';

import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { UserIcon } from '@heroicons/react/outline';

import LinkText from '../common/LinkText';
import TextInput from '../controls/TextInput';
import PasswordInput from '../controls/PasswordInput';
import SubmitButton from '../controls/SubmitButton';
import { createSignin, Signin, validateSignin } from '../models/forms';

interface Props {
  signIn: (signId: Signin) => void;
  isConfirmationError: boolean;
}

const SignInForm: React.FC<Props> = ({ isConfirmationError, signIn }) => {
  const { t } = useTranslation();

  return (
    <>
      <Formik
        initialValues={createSignin()}
        validate={(values) => validateSignin(values)}
        onSubmit={(values, { setSubmitting }) => {
          signIn(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, dirty, isValid }) => (
          <Form>
            <TextInput
              label={t('auth.email')}
              name='email'
              prefixIcon={<UserIcon className='h-6 w-6' />}
            />
            <PasswordInput name='password' label={t('auth.password')} />
            <SubmitButton
              isLoading={isSubmitting}
              text={t('auth.signInButton')}
              disabled={isSubmitting || !dirty || !isValid}
            />
          </Form>
        )}
      </Formik>
      {isConfirmationError && (
        <LinkText
          className='mt-2 text-center'
          text={t('auth.askNewConfrimation')}
          url='./ask-confirm'
        />
      )}
    </>
  );
};

export default SignInForm;
