import React from 'react';

const AuthCardHeader: React.FC = () => {
  return (
    <div className='flex justify-center items-center w-full h-24 primary-background rounded-t-3xl'>
      <p className='text-3xl font-semibold text-white'>
        <img
          src='/assets/images/meneoo-yellow.svg'
          alt='Meeneo Logo'
          className='h-20'
        />
      </p>
    </div>
  );
};

export default AuthCardHeader;
