import { io, Socket } from 'socket.io-client';

let socket: Socket;

export function getGuestSocket() {
  if (!socket) {
    socket = io('', {
      reconnectionDelay: 5000,
      autoConnect: false,
    });
  }
  return socket;
}
