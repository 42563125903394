import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  url: string;
  text: string;
  className?: string;
}

const LinkText: React.FC<Props> = ({ text, url, className }) => {
  return (
    <div className={className}>
      <Link to={url} className='text-base font-semibold text-gray-500'>
        {text}
      </Link>
    </div>
  );
};

export default LinkText;
