import React from 'react';
import AuthCardHeader from './AuthCardHeader';

interface Props {
  children: React.ReactNode;
}

const AuthCard: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div
        className='flex flex-col
        content-center
        w-full
        max-w-md
        m-auto
        bg-white
        shadow-3xl
        rounded-3xl'
      >
        <AuthCardHeader />
        {children}
      </div>
    </>
  );
};

export default AuthCard;
