import { FormikErrors } from 'formik';

import isEmpty from 'validator/lib/isEmpty';
import isStrongPassword from 'validator/lib/isStrongPassword';

export class ResetPassword {
  password: string = '';
  confirmPassword?: string = '';
  email?: string;
  token?: string;

  public static validate(values: ResetPassword): FormikErrors<ResetPassword> {
    const errors: any = {};

    if (isEmpty(values.password)) {
      errors.password = 'required';
    }

    const optionsPassword = {
      minLength: 6,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
      returnScore: false,
      pointsPerUnique: 0,
      pointsPerRepeat: 0,
      pointsForContainingLower: 0,
      pointsForContainingUpper: 0,
      pointsForContainingNumber: 0,
      pointsForContainingSymbol: 0,
    };

    if (
      !isEmpty(values.password) &&
      !isStrongPassword(values.password, optionsPassword)
    ) {
      errors.password = 'complexPassword';
    }

    if (isEmpty(values.confirmPassword!)) {
      errors.confirmPassword = 'required';
    }

    if (
      !isEmpty(values.confirmPassword!) &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = 'confirmPassword';
    }

    return errors;
  }
}
