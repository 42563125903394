export interface IReorderData {
  order: number;
}

export interface IReorderParam {
  data: IReorderData[];
  sourceIndex: number;
  destinationIndex: number;
}

export const reorder = (reorderParam: IReorderParam) => {
  const result = Array.from(reorderParam.data);

  const [removed] = result.splice(reorderParam.sourceIndex, 1);

  result.splice(reorderParam.destinationIndex, 0, removed);

  result.forEach((e, i) => {
    if (e) {
      e.order = i;
    }
  });

  return result;
};
