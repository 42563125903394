import { FormikErrors } from 'formik';
import isEmpty from 'validator/lib/isEmpty';

export interface FeedbackForm {
  description: string;
}

export const validateFeedbackForm = (
  values: FeedbackForm
): FormikErrors<FeedbackForm> => {
  const errors: any = {};

  if (isEmpty(values.description)) errors.description = 'required';

  return errors;
};
