import { FormikErrors } from 'formik';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isStrongPassword from 'validator/lib/isStrongPassword';

export interface SignUp {
  email: string;
  password: string;
  confirmPassword: string;
  locale?: string;
}

export const validateSignUp = (values: SignUp): FormikErrors<SignUp> => {
  const errors: any = {};

  if (isEmpty(values.email)) errors.email = 'required';

  if (!errors.email && !isEmail(values.email)) errors.email = 'email';

  if (isEmpty(values.password)) {
    errors.password = 'required';
  }

  const optionsPassword = {
    minLength: 6,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
    returnScore: false,
    pointsPerUnique: 0,
    pointsPerRepeat: 0,
    pointsForContainingLower: 0,
    pointsForContainingUpper: 0,
    pointsForContainingNumber: 0,
    pointsForContainingSymbol: 0,
  };

  if (
    !isEmpty(values.password) &&
    !isStrongPassword(values.password, optionsPassword)
  ) {
    errors.password = 'complexPassword';
  }

  if (isEmpty(values.confirmPassword)) {
    errors.confirmPassword = 'required';
  }

  if (isEmpty(values.confirmPassword)) {
    errors.confirmPassword = 'required';
  }

  if (
    !isEmpty(values.confirmPassword) &&
    values.password !== values.confirmPassword
  ) {
    errors.confirmPassword = 'confirmPassword';
  }

  return errors;
};

export const createSignUp = (): SignUp => {
  return { email: '', password: '', confirmPassword: '' };
};
