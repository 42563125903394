import { FormikErrors } from 'formik';

import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';

export interface Signin {
  email: string;
  password: string;
}

export const validateSignin = (values: Signin): FormikErrors<Signin> => {
  const errors: any = {};

  if (isEmpty(values.email)) errors.email = 'required';

  if (!errors.email && !isEmail(values.email)) errors.name = 'email';

  if (isEmpty(values.password)) errors.password = 'required';

  return errors;
};

export const createSignin = (): Signin => {
  return { email: '', password: '' };
};
