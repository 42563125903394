import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

export const getOrderDate = (date: Date) => {
  if (dayjs(date).isToday()) {
    return 'Today ' + dayjs(date).format('HH:mm');
  }

  if (dayjs(date).isYesterday()) {
    return 'Yesterday ' + dayjs(date).format('HH:mm');
  }

  return dayjs(date).format('YYYY-MM-DD H:m').toString();
};
