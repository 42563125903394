import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  touched: boolean;
  error: string | undefined;
}

const ValidationError: React.FC<Props> = ({ touched, error }: Props) => {
  const { t } = useTranslation();

  if (!touched || !error) return null;

  if (touched && error === 'required') {
    error = t('validation.required');
  }

  if (touched && error === 'email') {
    error = t('validation.email');
  }

  if (touched && error === 'complexPassword') {
    error = t('validation.complexPassword');
  }

  if (touched && error === 'confirmPassword') {
    error = t('validation.confirmPassword');
  }

  return <div className='text-xs font-semibold text-red-500 pl-1'>{error}</div>;
};

export default ValidationError;
