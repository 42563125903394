import React from 'react';

import { Route, Routes } from 'react-router';
import { QueryClientProvider } from 'react-query';
import { queryClient } from '../app/react-query/query-client';
import { ReactQueryDevtools } from 'react-query/devtools';

import Container from '../app/layout/Container';
import PageNotFound from './404/404';
import RedirectToSite from '../app/common/RedirectToSite';
import PropterSignIn from './propter/PropterSignIn';

const AuthCreatorRoutes = React.lazy(
  () => import('./creators/creators-auth/Routes')
);
const CreatorsRoutes = React.lazy(() => import('./creators/Routes'));
const GuestsRoutes = React.lazy(() => import('./guests/Routes'));
const PropterRoutes = React.lazy(() => import('./propter/Routes'));

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <Routes>
          <Route path='' element={<RedirectToSite />} />
          <Route path='/app/:slug/*' element={<GuestsRoutes />} />
          <Route path='/creators/*' element={<CreatorsRoutes />} />
          <Route path='/creators/auth/*' element={<AuthCreatorRoutes />} />
          <Route path='/propter/*' element={<PropterRoutes />} />
          <Route path='/propter/auth/*' element={<PropterSignIn />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Container>
      {process.env.REACT_APP_NODE_ENV !== 'production' && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      <div id='notification'></div>
      <div id='modal-root'></div>
    </QueryClientProvider>
  );
};

export default App;
