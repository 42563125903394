import { io, Socket } from 'socket.io-client';

let socket: Socket;

export function getCreatorSocket() {
  if (!socket) {
    socket = io('', {
      auth: (cb) => {
        cb({
          token: localStorage.getItem('jwt'),
        });
      },
      reconnectionDelay: 5000,
      autoConnect: false,
    });
  }
  return socket;
}
