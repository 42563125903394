import React from 'react';

interface Props {
  children?: React.ReactNode;
  title?: string;
}

const FormContainer: React.FC<Props> = ({ children, title }) => {
  return (
    <div className='flex flex-col content-center rounded-2xl p-5'>
      <p className='text-center text-base font-semibold text-gray-500 mb-7'>
        {title}
      </p>
      {children}
    </div>
  );
};

export default FormContainer;
