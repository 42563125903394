import React, { useEffect, useState } from 'react';

import { ClockIcon } from '@heroicons/react/outline';

interface Props {
  text: string;
  isLoading: boolean;
  disabled?: boolean;
  widthFull?: boolean;
}

const SubmitButton: React.FC<Props> = ({
  text,
  disabled,
  isLoading = false,
  widthFull = true,
}: Props) => {
  const [loading, setLoading] = useState(isLoading);

  let buttonClass =
    'flex items-center justify-center mt-2 disabled:bg-yellow-100 disabled:text-gray-500 font-semibold leading-none text-gray-700 py-4 px-10 bg-yellow-200 rounded hover:bg-yellow-300 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-300 focus:outline-none';

  if (widthFull) buttonClass = buttonClass + ' w-full';

  useEffect(() => {
    isLoading && setLoading(true);
    const timeout = setTimeout(() => {
      !isLoading && setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <button type='submit' disabled={disabled} className={buttonClass}>
      {loading && (
        <>
          {text}
          <ClockIcon className='animate-spin h-4 w-4 ml-2 text-red-600' />
        </>
      )}
      {!loading && <>{text}</>}
    </button>
  );
};

export default SubmitButton;
