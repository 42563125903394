import React from 'react';

const Copyright: React.FC = () => {
  return (
    <p className='text-gray-300 text-base font-normal text-center my-3'>
      Copyright @ Propter {new Date().getFullYear()}
    </p>
  );
};

export default Copyright;
