import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Signin } from '../../models/forms';
import { agent, errorMessage, successMessage } from '../../utils';

export const usePropterSignIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const signIn = async (signIn: Signin) => {
    try {
      const res = await agent.AuthPropter.signin(signIn);

      if (!res) {
        return Promise.reject('Credential error');
      }

      localStorage.setItem('jwt', res);

      successMessage(t('auth.welcomeMessage'));
      setTimeout(() => {
        navigate('/propter');
      }, 2000);
    } catch (error: any) {
      const status = error?.response?.status;

      if (status === 401) {
        errorMessage(t('auth.badCredentials'));
      }

      if (!status || status === 500) {
        errorMessage(t('common.serverError'));
      }
    }
  };

  return signIn;
};
