import { FormikErrors } from 'formik';
import isEmpty from 'validator/lib/isEmpty';

export interface PlaceForm {
  name: string;
}

export const validatePlaceForm = (
  values: PlaceForm
): FormikErrors<PlaceForm> => {
  const errors: any = {};

  if (isEmpty(values.name)) errors.name = 'required';

  return errors;
};
