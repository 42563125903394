import { FormikErrors } from 'formik';

import isEmpty from 'validator/lib/isEmpty';

import { ICategory, ILanguage } from '..';
import { ICreator } from '../creator';
import { IMenu } from '../menu';
import { PriceVariant } from './price-variant';

export interface IMenuForm {
  _id?: string;
  name: string;
  price: number;
  prices: PriceVariant[];
  description?: string;
  translation: Record<string, ITitleTranslate | ITitleDescriptionTranslate>;
  enableVariantPrice: boolean;
  enableDescription: boolean;
  imageFile?: string;
  imageUrl?: string;
}

interface IMenuTranslateForm {
  _id?: string;
  language: ILanguage;
}

export interface ITitleTranslate extends IMenuTranslateForm {
  name: string;
}

export interface ITitleDescriptionTranslate extends IMenuTranslateForm {
  name: string;
  description: string;
}

export function createMenuForm(
  creator: ICreator,
  category: ICategory,
  menu?: IMenu | null
): IMenuForm {
  const menuForm: IMenuForm = {
    name: menu ? menu.name : '',
    price: menu ? menu.price : 0,
    prices: menu ? menu.prices : [],
    description: menu
      ? menu.description
      : category.defaultDescription
      ? ''
      : undefined,
    imageUrl: menu ? menu.imageUrl : undefined,
    translation: {} as any,
    enableDescription: menu
      ? menu.enableDescription
      : category.defaultDescription,
    enableVariantPrice: menu
      ? menu.enableVariantPrice
      : category.defaultVariantPrices,
  };

  if (menu && creator) {
    creator.translates.forEach((e) => {
      menuForm.translation = Object.fromEntries(
        creator.translates.map((e) => {
          const men = menu.translation[e.code];

          return [
            e.code,
            {
              _id: e._id,
              language: men ? men.language : e,
              name: men ? men.name : '',
              description:
                men && men.template !== 0 ? men.description : undefined,
            },
          ];
        })
      );
    });
  } else {
    if (creator) {
      menuForm.translation = Object.fromEntries(
        creator.translates.map((e) => [
          e.code,
          !menuForm.description
            ? { _id: e._id, language: e, name: '' }
            : { _id: e._id, language: e, name: '', description: '' },
        ])
      );
    }
  }

  return menuForm;
}

export function validateMenu(values: IMenuForm): FormikErrors<IMenuForm> {
  const errors: FormikErrors<IMenuForm> = {};

  if (isEmpty(values.name)) errors.name = 'required';

  if (
    values.enableDescription &&
    isEmpty(values.description ? values.description : '')
  )
    errors.description = 'required';

  Object.entries(values.translation).map(([key, value]) => {
    if (isEmpty(value.name)) {
      errors['translation'] = {
        ...errors['translation'],
        [key]: { name: 'required' },
      };
    }

    return null;
  });

  return errors;
}
