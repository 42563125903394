import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import NProgress from 'nprogress';
import { history } from '../..';
import {
  ICategory,
  ICountry,
  ILanguage,
  ILocales,
  IPlace,
  ICreator,
  IMenu,
  IPropterCreator,
  IUser,
} from '../models';
import {
  SignUp,
  Signin,
  EmailFormModel,
  InitialFormModel,
  ResetPassword,
  ISettingsFormModel,
  ICategoryFormModel,
  IMenuForm,
  FeedbackForm,
} from '../models/forms';
import { IGuestResponse } from '../models/common';

const apiUrl = '/api/v1/';

axios.interceptors.request.use(
  (config: AxiosRequestConfig<any>) => {
    const token = localStorage.getItem('jwt');

    const headers = {
      'Content-Type': 'application/json',
    };

    config.headers = headers;

    config.headers.Authorization = `Bearer ${token}`;

    NProgress.start();

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response?.status === 401) {
      localStorage.removeItem('jwt');
      if (!history.location.pathname.endsWith('/auth')) {
        console.log(history.location.pathname.endsWith('/auth'));
        history.push('./auth');
        window.location.replace(window.location.href);
      }
    }

    NProgress.done();
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  patch: <T>(url: string, body: {}) =>
    axios.patch<T>(url, body).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const AuthCreator = {
  signup: (signUp: SignUp) =>
    requests.post<void>(`${apiUrl}creators/auth/signup`, signUp),
  confirmEmail: (email: string, token: string) =>
    requests.post<void>(`${apiUrl}creators/auth/emailVerification`, {
      email,
      token,
    }),
  signin: (signIn: Signin) =>
    requests.post<string>(`${apiUrl}creators/auth/signin`, signIn),
  askConfirmation: (emailForm: EmailFormModel) =>
    requests.post<void>(`${apiUrl}creators/auth/ask-confirmation`, emailForm),
  askForgotten: (emailForm: EmailFormModel) =>
    requests.post<void>(`${apiUrl}creators/auth/ask-forgotten`, emailForm),
  resetPassword: (resetForm: ResetPassword) =>
    requests.post<void>(`${apiUrl}creators/auth/reset-password`, resetForm),
};

const AuthPropter = {
  signin: (signIn: Signin) =>
    requests.post<string>(`${apiUrl}propter/auth/signin`, signIn),
};

const Creator = {
  getCreator: () => requests.get<ICreator | null>(`${apiUrl}creator`),
  post: (initialForm: InitialFormModel) =>
    requests.post<ICreator>(`${apiUrl}creator`, initialForm),
  put: (creatorId: string, settingsForm: ISettingsFormModel) =>
    requests.put<ICreator>(`${apiUrl}creator/${creatorId}`, settingsForm),
};

const Languages = {
  getLanguages: () => requests.get<ILanguage[]>(`${apiUrl}languages`),
};

const Countries = {
  getCountries: () => requests.get<ICountry[]>(`${apiUrl}countries`),
};

const Locales = {
  getLocales: () => requests.get<ILocales[]>(`${apiUrl}locales`),
};

const Categories = {
  getCategories: () => requests.get<ICategory[]>(`${apiUrl}categories`),
  post: (categoryForm: ICategoryFormModel) =>
    requests.post<ICategory>(`${apiUrl}categories`, categoryForm),
  put: (categoryForm: ICategoryFormModel) =>
    requests.put<ICreator>(
      `${apiUrl}categories/${categoryForm._id}`,
      categoryForm
    ),
  del: (id: string) => requests.del<void>(`${apiUrl}categories/${id}`),
  reorder: (categories: ICategory[]) =>
    requests.post<ICategory[]>(`${apiUrl}categories/reorder`, categories),
  postMenu: (categoryId: string, menu: IMenuForm) =>
    requests.post<{ status: string; data: ICategory }>(
      `${apiUrl}categories/${categoryId}/menus`,
      menu
    ),
  putMenu: (categoryId: string, menu: IMenuForm) =>
    requests.put<ICategory>(
      `${apiUrl}categories/${categoryId}/menus/${menu._id}`,
      menu
    ),
  deleteMenu: (categoryId: string, menuId: string) =>
    requests.del<ICategory>(
      `${apiUrl}categories/${categoryId}/menus/${menuId}`
    ),
  reorderMenu: (categoryId: string, menus: IMenu[]) =>
    requests.post<IMenu[]>(
      `${apiUrl}categories/${categoryId}/menus/reorder`,
      menus
    ),
};

const Guests = {
  getGuestData: (slug: string) =>
    requests.get<IGuestResponse>(`${apiUrl}guests/${slug}`),
  postStatistic: (body: { slug: string }) =>
    requests.post<void>(`${apiUrl}statistics`, body),
};

const Feedback = {
  post: (feedback: FeedbackForm) =>
    requests.post<void>(`${apiUrl}feedbacks`, feedback),
};

const Places = {
  getPlaces: () => requests.get<IPlace[]>(`${apiUrl}places`),
  post: (place: { name: string }) =>
    requests.post<IPlace>(`${apiUrl}places`, place),
  put: (place: { _id: string; name: string }) =>
    requests.put<IPlace>(`${apiUrl}places/${place._id}`, place),
  delete: (placeId: string) => requests.del<void>(`${apiUrl}places/${placeId}`),
};

const Propter = {
  getCreators: (params: string) =>
    requests.get<{ list: IPropterCreator[]; total: number }>(
      `${apiUrl}propter${params}`
    ),
  getCreator: (id: string) =>
    requests.get<{ creator: IPropterCreator; users: IUser[] }>(
      `${apiUrl}propter/${id}`
    ),
  changeUseOrder: (creatorId: string) =>
    requests.patch<ICreator>(
      `${apiUrl}propter/change-use-order/${creatorId}`,
      {}
    ),
  changeActiveUser: (userId: string) =>
    requests.patch<IUser>(`${apiUrl}propter/change-active-user/${userId}`, {}),
};

export const agent = {
  AuthPropter,
  AuthCreator,
  Creator,
  Languages,
  Countries,
  Locales,
  Categories,
  Guests,
  Feedback,
  Places,
  Propter,
};
