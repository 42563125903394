import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const ContentContainer: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className='md:flex md:justify-center md:items-center pt-2 h-screen w-screen auth-bg'>
        {children}
      </div>
    </>
  );
};

export default ContentContainer;
