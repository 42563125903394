import { FormikErrors, isObject } from 'formik';
import isEmpty from 'validator/lib/isEmpty';
import { ICountry } from '../country';
import { ILanguage } from '../language';

export interface ISettingsFormModel {
  _id: string;
  name: string;
  country: ICountry;
  place: string;
  address: string;
  applicationLanguage: ILanguage;
  defaultMenuLanguage: ILanguage;
  translates: ILanguage[];
}

export function validateSettings(
  values: ISettingsFormModel
): FormikErrors<ISettingsFormModel> {
  const errors: any = {};

  if (isEmpty(values.name)) errors.name = 'required';

  if (!isObject(values.country)) errors.country = 'required';

  if (isEmpty(values.place)) errors.place = 'required';

  if (isEmpty(values.address)) errors.address = 'required';

  if (!isObject(values.defaultMenuLanguage))
    errors.defaultMenuLanguage = 'required';

  return errors;
}
