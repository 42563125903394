import { FC } from 'react';

import AuthCard from '../../app/auth/AuthCard';
import SignInForm from '../../app/auth/SignInForm';
import Copyright from '../../app/common/Copyright';
import FormContainer from '../../app/auth/FormContainer';
import ContentContainer from '../../app/auth/ContentContainer';
import { usePropterSignIn } from '../../app/propter/hooks/usePropterSignIn';

const PropterSignIn: FC = () => {
  const signIn = usePropterSignIn();

  return (
    <ContentContainer>
      <AuthCard>
        <FormContainer title='Sign In'>
          <SignInForm signIn={signIn} isConfirmationError={false} />
          <Copyright />
        </FormContainer>
      </AuthCard>
    </ContentContainer>
  );
};

export default PropterSignIn;
