import { configureStore } from '@reduxjs/toolkit';

import creatorReducer from './creatorSlice';
import guestsReducer, { initialState } from './guestsSlice';

export const store = configureStore({
  reducer: {
    creator: creatorReducer,
    guests: guestsReducer,
  },
  preloadedState: {
    guests: {
      ...initialState,
      userName: localStorage.getItem('guest'),
    },
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
