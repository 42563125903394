import React from 'react';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ children }) => {
  return (
    <>
      <main>
        {children}
        <ToastContainer
          position='bottom-right'
          pauseOnFocusLoss={false}
          hideProgressBar={true}
          theme='light'
          autoClose={1000}
        />
      </main>
    </>
  );
};

export default Container;
