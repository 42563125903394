import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ILanguage } from '../models';
import { IOrderStatusModel } from '../models/common';
import { IGuestCategory, IGuestCreator, IGuestMenu } from '../models/common';
import type { RootState } from './store';

interface IGuestSlice {
  creator: IGuestCreator | null;
  language: ILanguage | null;
  orders: IGuestCategory[] | [];
  userName: string | null;
  selectedCategoryId: string | null;
  selectedMenuId: string | null;
  creatorStatus: string | undefined;
  ordersStatus: IOrderStatusModel[] | [];
}

export const initialState: IGuestSlice = {
  creator: null,
  language: null,
  orders: [],
  userName: null,
  selectedCategoryId: null,
  selectedMenuId: null,
  creatorStatus: undefined,
  ordersStatus: [],
};

const guestsSlice = createSlice({
  name: 'guests',
  initialState,
  reducers: {
    loadCreator: (state, action: PayloadAction<IGuestCreator | null>) => {
      state.creator = action.payload;
    },
    setLanguage: (state, action: PayloadAction<ILanguage | null>) => {
      state.language = action.payload;
    },
    changeOrders: (state, action: PayloadAction<IGuestMenu>) => {
      const category = state.orders.find(
        (e) => !!e.menus.find((m) => m._id === action.payload._id)
      );

      if (category) {
        category.menus = category.menus.filter(
          (e) => e._id !== action.payload._id
        );

        category.menus.push(action.payload);

        category.menus = [...category.menus].sort((a, b) =>
          a.order > b.order ? 1 : -1
        );
      }
    },
    loadOrdersInitial: (state, action: PayloadAction<IGuestCategory[]>) => {
      state.orders = action.payload;
    },
    setSelectedCategoryId: (state, action: PayloadAction<string | null>) => {
      state.selectedCategoryId = action.payload;
    },
    setSelectedMenuId: (state, action: PayloadAction<string | null>) => {
      state.selectedMenuId = action.payload;
    },
    setCreatorStatus: (state, action: PayloadAction<string | undefined>) => {
      state.creatorStatus = action.payload;
    },
    setUserName: (state, action: PayloadAction<string | null>) => {
      state.userName = action.payload;
    },
    setOrdersStatus: (
      state,
      action: PayloadAction<IOrderStatusModel[] | []>
    ) => {
      state.ordersStatus = action.payload;
    },
  },
});

export const {
  loadCreator,
  loadOrdersInitial,
  setLanguage,
  changeOrders,
  setSelectedCategoryId,
  setSelectedMenuId,
  setCreatorStatus,
  setUserName,
  setOrdersStatus,
} = guestsSlice.actions;

export const selectCreator = (state: RootState) => state.guests.creator;
export const selectSelectedLanguage = (state: RootState) =>
  state.guests.language;
export const selectOrders = (state: RootState) => state.guests.orders;
export const selectSelectedCategoryId = (state: RootState) =>
  state.guests.selectedCategoryId;
export const selectSelectedMenuId = (state: RootState) =>
  state.guests.selectedMenuId;
export const selectCreatorStatus = (state: RootState) =>
  state.guests.creatorStatus;
export const selectUserName = (state: RootState) => state.guests.userName;
export const selectActivatedOrders = (state: RootState) =>
  state.guests.orders
    .slice()
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((e) => {
      return {
        order: e.order,
        name: e.name,
        menus: e.menus
          .filter((e) => e.quantity > 0 || e.prices.find((p) => p.quantity > 0))
          .sort((a, b) => (a.order > b.order ? 1 : -1)),
      };
    })
    .filter((e) => e.menus.length > 0);

export const selectOrdersStatus = (state: RootState) =>
  state.guests.ordersStatus;

export default guestsSlice.reducer;
