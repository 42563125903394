import { toast } from 'react-toastify';

export const successMessage = (message: string): void => {
  toast.success(message);
};

export const errorMessage = (message: string): void => {
  toast.error(message);
};

export const warningMessage = (message: string): void => {
  toast.warning(message, { autoClose: false });
};

export const infoMessage = (message: string): void => {
  toast.info(message);
};
