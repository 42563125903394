import { FormikErrors } from 'formik';
import isEmpty from 'validator/lib/isEmpty';
import { ICategory, ILanguage } from '..';
import { ICreator } from '../creator';

export interface ICategoryFormModel {
  _id?: string;
  name: string;
  translation: Record<string, CategoryTranslateForm>;
}

export function createCategoryForm(
  creator?: ICreator,
  category?: ICategory | null
): ICategoryFormModel {
  const categoryForm: ICategoryFormModel = {
    name: category ? category.name : '',
    translation: {} as any,
  };

  if (category && creator) {
    creator.translates.forEach((e) => {
      categoryForm.translation = Object.fromEntries(
        creator.translates.map((e) => {
          const cat = category.translation[e.code];

          return [
            e.code,
            {
              _id: e._id,
              language: cat ? cat.language : e,
              name: cat ? cat.name : '',
            },
          ];
        })
      );
    });
  } else {
    if (creator) {
      categoryForm.translation = Object.fromEntries(
        creator.translates.map((e) => [
          e.code,
          { _id: e._id, language: e, name: '' },
        ])
      );
    }
  }

  return categoryForm;
}

export function validateCategory(
  values: ICategoryFormModel
): FormikErrors<ICategoryFormModel> {
  const errors: FormikErrors<ICategoryFormModel> = {};

  if (isEmpty(values.name)) errors.name = 'required';

  Object.entries(values.translation).map(([key, value]) => {
    if (isEmpty(value.name)) {
      errors['translation'] = {
        ...errors['translation'],
        [key]: { name: 'required' },
      };
    }
    return null;
  });

  return errors;
}

export interface CategoryTranslateForm {
  _id?: string;
  language: ILanguage;
  name: string;
}
