import { FormikErrors, isObject } from 'formik';
import isEmpty from 'validator/lib/isEmpty';
import { ILanguage } from '..';

export interface InitialFormModel {
  name: string;
  country: string;
  place: string;
  address: string;
  applicationLanguage: ILanguage | undefined;
  defaultMenuLanguage: ILanguage | undefined;
  currencySymbol: string;
  translates: ILanguage[];
}

export const validateInitialFormModel = (
  values: InitialFormModel
): FormikErrors<InitialFormModel> => {
  const errors: any = {};

  if (isEmpty(values.name)) errors.name = 'required';

  if (!isObject(values.country)) errors.country = 'required';

  if (isEmpty(values.place)) errors.place = 'required';

  if (isEmpty(values.address)) errors.address = 'required';

  if (isEmpty(values.currencySymbol)) errors.currencySymbol = 'required';

  if (!isObject(values.defaultMenuLanguage))
    errors.defaultMenuLanguage = 'required';

  return errors;
};

export const createInitialFormModel = (): InitialFormModel => {
  return {
    name: '',
    country: '',
    place: '',
    address: '',
    applicationLanguage: undefined,
    defaultMenuLanguage: undefined,
    currencySymbol: '',
    translates: [],
  };
};
