import { FormikErrors } from 'formik';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';

export interface EmailFormModel {
  email: string;
}

export const validateEmailFormModel = (
  values: EmailFormModel
): FormikErrors<EmailFormModel> => {
  const errors: any = {};

  if (isEmpty(values.email)) errors.email = 'required';

  if (!errors.email && !isEmail(values.email)) errors.email = 'required';

  return errors;
};
