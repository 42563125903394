import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { IGuest } from '../models';
import { IReceivedOrder } from '../models/common';

interface ICreatorSlice {
  selectedCategoryId: string | null;
  selectedMenuId: string | null;
  selectedOrderId: string | null;
  selectedPlaceId: string | null;
  guests: IGuest[];
  orders: IReceivedOrder[];
}

const initialState: ICreatorSlice = {
  selectedCategoryId: null,
  selectedMenuId: null,
  selectedOrderId: null,
  selectedPlaceId: null,
  guests: [],
  orders: [],
};

export const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {
    setSelectedCategoryId: (state, action: PayloadAction<string | null>) => {
      state.selectedCategoryId = action.payload;
    },
    setSelectedMenuId: (state, action: PayloadAction<string | null>) => {
      state.selectedMenuId = action.payload;
    },
    setSelectedOrderId: (state, action: PayloadAction<string | null>) => {
      state.selectedOrderId = action.payload;
    },
    setSelectedPlaceId: (state, action: PayloadAction<string | null>) => {
      state.selectedPlaceId = action.payload;
    },
    updateGuestsList: (state, action: PayloadAction<IGuest[]>) => {
      state.guests = action.payload;
    },
    updateOrdersList: (state, action: PayloadAction<IReceivedOrder[]>) => {
      state.orders = action.payload;
    },
  },
});

export const {
  setSelectedCategoryId,
  setSelectedMenuId,
  setSelectedOrderId,
  setSelectedPlaceId,
  updateGuestsList,
  updateOrdersList,
} = creatorSlice.actions;

export const selectSelectedCategoryId = (state: RootState) =>
  state.creator.selectedCategoryId;
export const selectSelectedMenuId = (state: RootState) =>
  state.creator.selectedMenuId;
export const selectGuests = (state: RootState) => state.creator.guests;
export const selectGuestsCount = (state: RootState) =>
  state.creator.guests.length;
export const selectReceivedOrders = (state: RootState) => state.creator.orders;
export const selectReceivedStatusCount = (state: RootState) =>
  state.creator.orders.filter((e) => e.status === 'received').length;
export const selectSelectedOrderId = (state: RootState) =>
  state.creator.selectedOrderId;
export const selectSelectedPlaceId = (state: RootState) =>
  state.creator.selectedPlaceId;

export default creatorSlice.reducer;
